import * as THREE from "three";

// Функція для перевірки, чи знаходиться точка всередині полігону в тривимірному просторі
export const isPointInPolygon3D = (point: THREE.Vector3, vertices: THREE.Vector3[]): boolean => {
  // Визначення нормалі полігону
  const normal = new THREE.Vector3();
  normal
    .crossVectors(
      new THREE.Vector3().subVectors(vertices[1], vertices[0]),
      new THREE.Vector3().subVectors(vertices[2], vertices[0])
    )
    .normalize();

  // Перевірка, чи лежить точка на площині
  const plane = new THREE.Plane().setFromNormalAndCoplanarPoint(normal, vertices[0]);
  if (Math.abs(plane.distanceToPoint(point)) > 1e-10) {
    return false;
  }

  // Проєкція точки та вершин полігону на площину, перпендикулярну до нормалі
  const basisX = new THREE.Vector3();
  const basisY = new THREE.Vector3();
  const basisZ = normal;
  if (Math.abs(basisZ.x) > Math.abs(basisZ.y)) {
    basisX.set(-basisZ.z, 0, basisZ.x).normalize();
  } else {
    basisX.set(0, -basisZ.z, basisZ.y).normalize();
  }
  basisY.crossVectors(basisZ, basisX).normalize();

  const projectTo2D = (v: THREE.Vector3): THREE.Vector2 => {
    const x = v.dot(basisX);
    const y = v.dot(basisY);
    return new THREE.Vector2(x, y);
  };

  const projectedPoint = projectTo2D(point);
  const projectedVertices = vertices.map(projectTo2D);

  // Перевірка в 2D, чи знаходиться точка всередині полігону
  let isInside = false;
  for (let i = 0, j = projectedVertices.length - 1; i < projectedVertices.length; j = i++) {
    const xi = projectedVertices[i].x,
      yi = projectedVertices[i].y;
    const xj = projectedVertices[j].x,
      yj = projectedVertices[j].y;

    const intersect =
      yi > projectedPoint.y !== yj > projectedPoint.y &&
      projectedPoint.x < ((xj - xi) * (projectedPoint.y - yi)) / (yj - yi) + xi;
    if (intersect) isInside = !isInside;
  }

  return isInside;
};
