import React, { useEffect, useRef } from "react";

// scss
import s from "./CategoriesGroup.module.scss";

export const CategoriesGroup = ({ ...props }) => {
  const { children, embeddedView } = props;
  const scrollableBlock = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (scrollableBlock.current) {
      scrollableBlock.current.scrollTop = 0;
    }
  }, [embeddedView]);
  return (
    <div ref={scrollableBlock} className={s.categoriesGroup}>
      {children}
    </div>
  );
};
