import * as THREE from "three";
import { ATTRIBUTE_NAMES } from "../../utils/constants/attributesThreekitRoomBuilder";
import { getIndexWallNextToLeft, getIndexWallNextToRight } from "./wallLength";
import { IConfiguration, IConfigurationAsset } from "@threekit-tools/treble/dist/types";
import { ATTRIBUTES_NAMES_THREEKIT } from "../../utils/constants/attributesThreekit";

export const getStartPointFromConfigurationWall = (wallValue: any): THREE.Vector2 => {
  return new THREE.Vector2(
    wallValue["configuration"][ATTRIBUTE_NAMES.wallStartX],
    wallValue["configuration"][ATTRIBUTE_NAMES.wallStartZ]
  );
};

export const getEndPointFromConfigurationWall = (wallValue: any): THREE.Vector2 => {
  return new THREE.Vector2(
    wallValue["configuration"][ATTRIBUTE_NAMES.wallEndX],
    wallValue["configuration"][ATTRIBUTE_NAMES.wallEndZ]
  );
};

export const getWallLength = (startPoint: THREE.Vector2, endPoint: THREE.Vector2): number => {
  return Math.sqrt((endPoint["x"] - startPoint["x"]) ** 2 + (endPoint["y"] - startPoint["y"]) ** 2);
};

export const roundingVector2 = (coords: THREE.Vector2): THREE.Vector2 => {
  return new THREE.Vector2(Number(coords["x"].toFixed(2)), Number(coords["y"].toFixed(2)));
};

export interface IntervalWallI {
  start: THREE.Vector2;
  end: THREE.Vector2;
}

export const getIntervalWall = (wallConfig: any[]): IntervalWallI => {
  return {
    start: getStartPointFromConfigurationWall(wallConfig),
    end: getEndPointFromConfigurationWall(wallConfig),
  };
};

export const getIntervalWallRounding = (wallConfig: IConfigurationAsset): IntervalWallI => {
  return {
    start: roundingVector2(getStartPointFromConfigurationWall(wallConfig)),
    end: roundingVector2(getEndPointFromConfigurationWall(wallConfig)),
  };
};

export type IntervalsWallsT = IntervalWallI[];
export const getArrIntervalsWalls = (wallAttributeValue: any[]): IntervalsWallsT => {
  return wallAttributeValue.reduce((accWallsSegments: IntervalsWallsT, wallValue: any) => {
    const start = getStartPointFromConfigurationWall(wallValue);
    const end = getEndPointFromConfigurationWall(wallValue);
    accWallsSegments.push({
      start: roundingVector2(start),
      end: roundingVector2(end),
    });
    return accWallsSegments;
  }, []);
};

export const getIndexWallAdjacentAtStart = (arrWalls: any, objWall: any, indxCurrentWall: number): number => {
  const startPointWall = getStartPointFromConfigurationWall(objWall);
  const indxWallNextToLeft = getIndexWallNextToLeft(arrWalls, indxCurrentWall, startPointWall);
  return indxWallNextToLeft;
};

export const getIndexWallAdjacentAtEnd = (arrWalls: any, objWall: any, indxCurrentWall: number): number => {
  const endPointWall = getEndPointFromConfigurationWall(objWall);
  const indxWallNextToRight = getIndexWallNextToRight(arrWalls, indxCurrentWall, endPointWall);
  return indxWallNextToRight;
};

export const checkEqualsCoordsWalls = (coordsWallFirst: IntervalWallI, coordsWallLast: IntervalWallI) => {
  // Порівнюємо початкові та кінцеві точки обох проміжків
  const isFirstEqual =
    coordsWallFirst.start.equals(coordsWallLast.start) && coordsWallFirst.end.equals(coordsWallLast.end);

  // Порівнюємо початкову точку першого проміжку з кінцевою точкою другого проміжку
  const isReversedEqual =
    coordsWallFirst.start.equals(coordsWallLast.end) && coordsWallFirst.end.equals(coordsWallLast.start);

  // Повертаємо true, якщо хоча б одна з умов виконана
  return isFirstEqual || isReversedEqual;
};

/**
 * Функція для визначення напрямку лінії, яка з'єднує дві точки на площині (початкову точку і кінцеву точку).
 *
 * @param {THREE.Vector2} start Координати початкової точки лінії
 * @param {THREE.Vector2} end Координати кінцевої точки лінії
 * @return {-1 | 1} -1 - Лінія побудована За годинниковою стрілкою або лінія Горизонтальна
 * 1 - Лінія побудована Проти годинникової стрілки
 */
export const findLineDirectionClockwise = (start: THREE.Vector2, end: THREE.Vector2): 1 | -1 => {
  if (start["x"] === end["x"] && start["y"] === end["y"]) {
    return -1; // Лінія має нульову довжину;
  }
  // Добуток векторів (x2, y2) і (-y1, x1)
  const dotProduct = end["x"] * -start["y"] + end["y"] * start["x"];
  if (dotProduct > 0) {
    return -1; // Лінія побудована За годинниковою стрілкою;
  } else {
    return 1; // Лінія побудована Проти годинникової стрілки;
  }
};

/**
 * Функція визначає спільну точку для двух стін.
 *
 * @param {IntervalWallI} wall1 Координати початкової точки лінії
 * @param {IntervalWallI} wall2 Координати кінцевої точки лінії
 * @return {THREE.Vector2 | undefined} Спільна точка або undefined якщо немає точки перетину
 */
export const getIntersectPointForWalls = (wall1: IntervalWallI, wall2: IntervalWallI): THREE.Vector2 | undefined => {
  if (wall1["start"].equals(wall2["start"]) || wall1["start"].equals(wall2["end"])) {
    return wall1["start"];
  }
  return wall1["end"];
};

/**
 * Функція визначає чи стіна Boundary по конфігурації стіни.
 *
 * @param {IConfigurationAsset} wallConfiguration 2Д конфігурація стіни
 * @return {boolean} true - стіна Boundary, false - стіна суцільна
 */
export const checkWallBoundary = (wallConfiguration: IConfigurationAsset): boolean => {
  const configuration = wallConfiguration.configuration;
  if (configuration === undefined || typeof configuration === "string") return false;
  const attributeBoundary = configuration[ATTRIBUTE_NAMES.isVisible];
  // @ts-ignore
  const attributeBoundaryName = attributeBoundary?.name;
  if (attributeBoundaryName === "Invisible") return true;
  return false;
};

/**
 * Функція визначає чи співпадають конфігурації стін. Кастомне порівняння значень конфігурації.
 *
 * @param {IConfigurationAsset} wallConfiguration 2Д конфігурація стіни
 * @return {boolean} true - стіна Boundary, false - стіна суцільна
 */
export const isEqualWallsConfigurations = (
  oldWallConfiguration: string | IConfiguration,
  newWallConfiguration: string | IConfiguration
): boolean => {
  if (oldWallConfiguration === newWallConfiguration) return true;

  if (
    typeof oldWallConfiguration !== "object" ||
    typeof newWallConfiguration !== "object" ||
    oldWallConfiguration === null ||
    newWallConfiguration === null
  ) {
    return false;
  }

  for (let key in oldWallConfiguration) {
    if (
      oldWallConfiguration.hasOwnProperty(key) &&
      typeof oldWallConfiguration[key] !== "object" &&
      key !== ATTRIBUTES_NAMES_THREEKIT.SYSTEM_UPDATE_FEATURES
    ) {
      if (oldWallConfiguration[key] !== newWallConfiguration[key]) {
        return false;
      }
    }
  }
  return true;
};
