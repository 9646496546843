import { ICoordinates, IDisplayAttributeArrayValue } from "@threekit-tools/treble/dist/types";
import { ATTRIBUTES_NAMES_THREEKIT } from "../../../utils/constants/attributesThreekit";
import { ProductArrT } from "../../../utils/constants/cabinets";
import {
  WallItemT,
} from "../../../utils/constants/nodesNamesThreekit";
import { getAllWallsNode } from "../../wallsAndFloor/buildWallFromData";
import { checkWallVisibility, getNextWall } from "../../wallsAndFloor/getGeneralWallInfo";
import { getWallInFrontCamera } from "../../wallsAndFloor/getWallInFrontCamera";
import { getValuesAttributeFromProducts } from "../getObjActiveAndNewValuesAttributeThreekit";
import { checkEmptyIntervalInWallForCabinetBase, checkEmptyIntervalInWallForCabinetsBase } from "../../intervals/checkEmptyIntervalInWall";
import { getWidthModelFromMetadataAttribute } from "../metadata";
import { checkIfLazySuzanCornerCabinet } from "./checkCornersCabinetsBase";
import { CABINETS_BASE_HEIGHT_FROM_COUTERTOP, CABINETS_BASE_STANDART_DEPTH } from "../constatns";

/**
 * Рекурсивна функція. Шукає стіну, яка є видимою 
 * та на якій є місце для розташування шкафів (тобто стіне заповнена повністю шкафами)
 * Перевіряє стіну, що прийшла в параметрі,
 * якщо стіна не проходить перевірку, перевіряє наступну стіну
 * Таким чином перевіряє всі стіни
 *
 * @param {WallItemT} wallName Name стіни, для якої відбувається веревірка на видимість та заповненість
 * @param {number} wallCount = 0. Для виходу з рекурсії, коли перевірені всі стіни
 * @param {number} allWallsLength Кількість всіх стін в кімнаті. Для виходу з рекурсії, коли перевірені всі стіни
 * @param {IDisplayAttributeArrayValue[]} valuesAttributeForAdded Масив values атрибуту, які мають додатися на сцену
 * @return {WallItemT | undefined} Name стіни, яка пройшла перевірку,
 * або undefined - якщо в кімнаті жодна стіна не пройшла перевірку на видимість та заповненість іншими шкафами
 */
const getTargetWallNameForAddedCabinetsBase = (
  wallName: WallItemT,
  wallCount: number = 0,
  allWallsLength: number,
  valuesAttributeForAdded: IDisplayAttributeArrayValue[]
): WallItemT | undefined => {

  const currentWallName = wallName;

  if (wallCount >= allWallsLength) {
    return undefined;
  }

  const isVisibleWall = checkWallVisibility(currentWallName);
  const isIntervalInWall = checkEmptyIntervalInWallForCabinetsBase(
    currentWallName,
    valuesAttributeForAdded
  )

  if (isVisibleWall && isIntervalInWall) {
    return currentWallName;
  } else {
    // Функція для пошуку вільної видимої стіни
    const nextWallName = getNextWall(currentWallName);
    return getTargetWallNameForAddedCabinetsBase(
      nextWallName,
      wallCount + 1,
      allWallsLength,
      valuesAttributeForAdded
    );
  }
};

/**
 * Шукає стіну, яка є видимою 
 * та на якій є місце для розташування напольних шкафів (тобто стіна не заповнена повністю напольними шкафами)
 *
 * @param {ProductArrT} arrProductsInfo Масив продуктів зі стейту, які мають додатися на стіну
 * @return {WallItemT | undefined} Name стіни, яка пройшла перевірку,
 * або undefined - якщо в кімнаті жодна стіна не пройшла перевірку на видимість та заповненість іншими шкафами
 */
export const checkWallForAddedCabinetsBaseArray = (
  arrProductsInfo: ProductArrT
): WallItemT | undefined => {

  const wallNameInFrontCamera = getWallInFrontCamera();
  const allWallsLength = Object.keys(getAllWallsNode()).length;

  const valuesAttributeForAdded = getValuesAttributeFromProducts(
    arrProductsInfo,
    ATTRIBUTES_NAMES_THREEKIT.CABINETS_BASE
  );

  // const targetWallName = getTargetWallNameForAddedCabinetsBase(
  //   wallNameInFrontCamera,
  //   0,
  //   allWallsLength,
  //   valuesAttributeForAdded
  // );

  let targetWallName: WallItemT | undefined = undefined;
  valuesAttributeForAdded.forEach((valueAttribute) => {
    if (targetWallName) return;
    const widthNewModel = getWidthModelFromMetadataAttribute(valueAttribute);
    const depthNewModel = checkIfLazySuzanCornerCabinet(valueAttribute) ? widthNewModel : CABINETS_BASE_STANDART_DEPTH;
    targetWallName = getTargetWallNameForAddedOneCabinetBase(
      wallNameInFrontCamera,
      0,
      allWallsLength,
      {x: widthNewModel || 0, y: CABINETS_BASE_HEIGHT_FROM_COUTERTOP, z: depthNewModel || 0}
    )
  })

  return targetWallName;
};

/**
 * Рекурсивна функція. Шукає стіну, яка є видимою 
 * та на якій є місце для розташування шкафа розміром sizeModel
 * Перевіряє стіну, що прийшла в параметрі,
 * якщо стіна не проходить перевірку, перевіряє наступну стіну
 * Таким чином перевіряє всі стіни
 *
 * @param {WallItemT} wallName Name стіни, для якої відбувається перевірка на видимість та заповненість
 * @param {number} wallCount = 0. Для виходу з рекурсії, коли перевірені всі стіни
 * @param {number} allWallsLength Кількість всіх стін в кімнаті. Для виходу з рекурсії, коли перевірені всі стіни
 * @param {ICoordinates[]} sizeModel Розміри моделі для якої потрібно перевірити наявність місця на стінах.
 * @return {WallItemT | undefined} Name стіни, яка пройшла перевірку,
 * або undefined - якщо в кімнаті жодна стіна не пройшла перевірку на видимість та заповненість іншими шкафами
 */
export const getTargetWallNameForAddedOneCabinetBase = (
  wallName: WallItemT,
  wallCount: number = 0,
  allWallsLength: number,
  sizeModel: ICoordinates
): WallItemT | undefined => {

  const currentWallName = wallName;

  if (wallCount >= allWallsLength) {
    return undefined;
  }

  const isVisibleWall = checkWallVisibility(currentWallName);
  const isIntervalForModel = checkEmptyIntervalInWallForCabinetBase(currentWallName, sizeModel);

  if (isVisibleWall && isIntervalForModel) {
    return currentWallName;
  } else {
    // Функція для пошуку вільної видимої стіни
    const nextWallName = getNextWall(currentWallName);
    return getTargetWallNameForAddedOneCabinetBase(
      nextWallName,
      wallCount + 1,
      allWallsLength,
      sizeModel
    );
  }
};
