import { calculateClockwiseAngle } from "./angle";
import {
  findLineDirectionClockwise,
  getIndexWallAdjacentAtEnd,
  getIndexWallAdjacentAtStart,
  getIntervalWall,
} from "./wallsGeneral";

export const checkWallForInnerCorner = (
  arrWalls: any,
  objCurrentWall: any,
  indxCurrentWall: number
) => {
  const indxWallNeaborStart = getIndexWallAdjacentAtStart(
    arrWalls,
    objCurrentWall,
    indxCurrentWall
  );
  const indxWallNeaborEnd = getIndexWallAdjacentAtEnd(
    arrWalls,
    objCurrentWall,
    indxCurrentWall
  );

  if (indxWallNeaborStart === -1 || indxWallNeaborEnd === -1) return false;

  const wallNeaborStartConfig = arrWalls[indxWallNeaborStart];
  const wallNeaborEndConfig = arrWalls[indxWallNeaborEnd];

  const currentLine = getIntervalWall(objCurrentWall);
  const neaborStartLine = getIntervalWall(wallNeaborStartConfig);
  const neaborEndLine = getIntervalWall(wallNeaborEndConfig);

  // Визначаємо напря по годинниковій стрілці по якому побудований відрізок
  const lineDirectionClockwise = findLineDirectionClockwise(
    currentLine["start"],
    currentLine["end"]
  );

  let testAngleNeaborStart = 0;
  let testAngleNeaborEnd = 0;

  if (lineDirectionClockwise < 0) {
    testAngleNeaborStart = calculateClockwiseAngle(
      neaborStartLine,
      currentLine
    );
    testAngleNeaborEnd = calculateClockwiseAngle(
      currentLine,
      neaborEndLine
    );
  } else {
    testAngleNeaborStart = calculateClockwiseAngle(
      currentLine,
      neaborStartLine
    );
    testAngleNeaborEnd = calculateClockwiseAngle(
      neaborEndLine,
      currentLine
    );
  }

  const isInnerAngleNeaborStart = testAngleNeaborStart < 180;
  const isInnerAngleNeaborEnd = testAngleNeaborEnd < 180;

  return isInnerAngleNeaborStart || isInnerAngleNeaborEnd;
};
