import {
  IConfiguration,
  IConfigurationAttribute,
  ICoordinates,
  IQuaternion,
  ISceneQuery,
  ISceneResult,
  PLUG_TYPES,
  TRANSFORM_PROPERTY_TYPES,
} from "@threekit-tools/treble/dist/types";
import { listActiveSettingT } from "../../../store/slices/settingsUI/typesSettingsUISlice";
import {
  GetQueryThreekitI,
  getSceneInstanceId,
  getInstanceIdAsset,
  getFullConfigurationAttribute,
} from "./getFunctions";

export interface SetQueryThreekitI extends GetQueryThreekitI {
  value: any;
}

export const getObjSetQueryParamsThreekit = (
  data: SetQueryThreekitI
): GetQueryThreekitI => {
  let generatedObjQuery: GetQueryThreekitI = {};

  if (!!data["from"]) generatedObjQuery["from"] = data["from"];

  if (!!data["id"]) {
    generatedObjQuery["id"] = data["id"];
  } else if (!!data["name"]) {
    generatedObjQuery["name"] = data["name"];
  }

  return generatedObjQuery;
};

// подивитися до типізації !#!
export const setConfiguration = async (attr: string, data: any) => {
  return await window.threekit.configurator.setConfiguration({ [attr]: data });
};

// подивитися до типізації !#!
export const setConfigurationFull = async (attr: string, data: any) => {
  //@ts-ignore
  return await window.threekit.configurator.setFullConfiguration({
    [attr]: data,
  });
};

export const setConfigurationFullAll = async (objConfiguration: any) => {
  //@ts-ignore
  return await window.threekit.player.player.configurator.setFullConfiguration(objConfiguration);
};

export const setThreekitInfo = (
  objQuery: ISceneQuery,
  value: IConfigurationAttribute
) => {
  //@ts-ignore
  return window.threekit.player.scene.set(objQuery, value);
};

export const setAllThreekitInfo = (
  objQuery: ISceneQuery,
  value: IConfigurationAttribute
) => {
  //@ts-ignore
  return window.threekit.player.scene.setAll(objQuery, value);
};

export const setThreeKitObj = (objThreeKit: { [key: string]: any }) => {
  if (Object.keys(objThreeKit).length > 0) {
    return window.threekit.configurator.setConfiguration(objThreeKit);
  }
  return Promise.resolve();
};

export const setVisibleWithoutInstance = (name: string, value: boolean) => {
  window.threekit.player.scene.set(
    {
      name: name,
      //@ts-ignore
      plug: "Properties",
      //@ts-ignore
      property: "visible",
    },
    value
  );
};

export const setVisible = (data: SetQueryThreekitI) => {
  let objQuery: ISceneQuery = {
    //@ts-ignore
    plug: "Properties",
    //@ts-ignore
    property: "visible",
  };
  //@ts-ignore
  objQuery = { ...objQuery, ...getObjSetQueryParamsThreekit(data) };
  return setThreekitInfo(objQuery, data["value"]);
}

export const setVisibleAll = (data: SetQueryThreekitI) => {
  let objQuery: ISceneQuery = {
    //@ts-ignore
    plug: "Properties",
    //@ts-ignore
    property: "visible",
  };
  //@ts-ignore
  objQuery = { ...objQuery, ...getObjSetQueryParamsThreekit(data) };
  return setAllThreekitInfo(objQuery, data["value"]);
}

export const setThreekitOperatorProperties = (
  from: string,
  name: string,
  propertiesName: string,
  value: any
) => {
  window.threekit.player.scene.set(
    {
      from: from,
      name: name,
      //@ts-ignore
      plug: "PolyMesh",
      properties: {
        name: propertiesName,
      },
      property: "translation" as TRANSFORM_PROPERTY_TYPES.TRANSLATION,
    },
    value
  );
};

export const setPolyMeshHeightThreekit = (data: SetQueryThreekitI) => {
  let objQuery: ISceneQuery = {
    //@ts-ignore
    plug: "PolyMesh",
    //@ts-ignore
    property: "height",
  };
  //@ts-ignore
  objQuery = { ...objQuery, ...getObjSetQueryParamsThreekit(data) };
  return setThreekitInfo(objQuery, data["value"]);
};

// зробити базову ф-цію  !#!
export const setMaterialThreekit = (data: SetQueryThreekitI) => {
  let objQuery: ISceneQuery = {
    //@ts-ignore
    plug: "Material",
    //@ts-ignore
    property: "asset",
  };
  //@ts-ignore
  objQuery = { ...objQuery, ...getObjSetQueryParamsThreekit(data) };
  return setThreekitInfo(objQuery, data["value"]);
};

export const setMeshesLayoutContainer = (
  nameLayoutContainer: string,
  value: IConfigurationAttribute
) => {
  const objQuery: ISceneQuery = {
    from: getSceneInstanceId(),
    name: nameLayoutContainer,
    //@ts-ignore
    plug: "LayoutContainer",
    //@ts-ignore
    property: "containerMeshes",
  };
  return setThreekitInfo(objQuery, value);
};

export const setTranslationThreekit = (data: SetQueryThreekitI) => {
  let objQuery = {
    plug: "Transform" as PLUG_TYPES.TRANSFORM,
    property: "translation" as TRANSFORM_PROPERTY_TYPES.TRANSLATION,
  };
  objQuery = { ...objQuery, ...getObjSetQueryParamsThreekit(data) };
  return setThreekitInfo(objQuery, data["value"]);
};

export const setRotationThreekit = (data: SetQueryThreekitI) => {
  let objQuery: ISceneQuery = {
    //@ts-ignore
    plug: "Transform",
    //@ts-ignore
    property: "rotation",
  };
  //@ts-ignore
  objQuery = { ...objQuery, ...getObjSetQueryParamsThreekit(data) };
  return setThreekitInfo(objQuery, data["value"]);
};

export const setScaleThreekit = (data: SetQueryThreekitI) => {
  let objQuery: ISceneQuery = {
    //@ts-ignore
    plug: "Transform",
    //@ts-ignore
    property: "scale",
  };
  //@ts-ignore
  objQuery = { ...objQuery, ...getObjSetQueryParamsThreekit(data) };
  return setThreekitInfo(objQuery, data["value"]);
};

export const setActiveTagsThreekit = (data: SetQueryThreekitI) => {
  let objQuery: ISceneQuery = {
    //@ts-ignore
    plug: "Properties",
    properties: {
      name: "Tags",
    },
    //@ts-ignore
    property: "active"
  };
  //@ts-ignore
  objQuery = { ...objQuery, ...getObjSetQueryParamsThreekit(data) };
  return setThreekitInfo(objQuery, data["value"]);
};

export const setPositionCamera = (camTrans: ICoordinates) =>
  window["threekit"]["player"]["camera"].setPosition(camTrans);

export const setPositionQuatCamera = (camQuat: IQuaternion) =>
  window["threekit"]["player"]["camera"].setQuaternion(camQuat);

export const setActiveCamera = async (id: string) => {
  //@ts-ignore
  await window.threekit.player.setActiveCamera(id);
};

export const setActiveColliderThreekit = (data: SetQueryThreekitI) => {
  let objQuery: ISceneQuery = {
    //@ts-ignore
    plug: "Physics",
    properties: {
      name: "Collider",
    },
    //@ts-ignore
    property: "active"
  };
  //@ts-ignore
  objQuery = { ...objQuery, ...getObjSetQueryParamsThreekit(data) };
  return setThreekitInfo(objQuery, data["value"]);
};



export const setNodeMetadataValue = (data: SetQueryThreekitI): string => {
  let objQuery: ISceneQuery = {
    //@ts-ignore
    plug: "Properties",
    properties: {
      name: "MetaData",
    },
    //@ts-ignore
    key: data?.key,
    //@ts-ignore
    property: "value",
  };
  //@ts-ignore
  objQuery = { ...objQuery, ...getObjSetQueryParamsThreekit(data) };
  //@ts-ignore
  return setThreekitInfo(objQuery, data["value"]);
}