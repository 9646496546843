import { IConfigurationArray } from "@threekit-tools/treble/dist/types";
import { ATTRIBUTES_NAMES_THREEKIT } from "../../utils/constants/attributesThreekit"
import { ATTRIBUTE_NAMES } from "../../utils/constants/attributesThreekitRoomBuilder";
import { getFullConfigurationAttribute } from "../../utils/threekit/general/getFunctions"
import { CabinetsAndFeatures_NodesT, FeaturesName_NodesT, NODES_THREEKIT } from "../../utils/constants/nodesNamesThreekit";
import { checkWindowPositionForCabinetsBase } from "./positionsFeature";

export const checkFeatureWindow = (
  nullNameFeature: FeaturesName_NodesT
): boolean => nullNameFeature.includes(NODES_THREEKIT.MODEL_WINDOW);

export const checkFeatureDoor = (
  nullNameFeature: FeaturesName_NodesT
): boolean => nullNameFeature.includes(NODES_THREEKIT.MODEL_DOOR);

export const checkFeatureOpening = (
  nullNameFeature: FeaturesName_NodesT
): boolean => nullNameFeature.includes(NODES_THREEKIT.MODEL_OPENING);

export const getWallConnections = (wallIndex: number): string[] => {
  const configurationWalls = getFullConfigurationAttribute(ATTRIBUTES_NAMES_THREEKIT.WALLS) as IConfigurationArray;
  const wallIndexValue = configurationWalls[wallIndex];
  if (wallIndexValue === undefined) return [];
  const wallConfiguration = wallIndexValue["configuration"];
  if (wallConfiguration !== undefined && typeof wallConfiguration !== 'string') {
    if(!wallConfiguration[ATTRIBUTE_NAMES.wallConnections]) return []
    return JSON.parse(String(wallConfiguration[ATTRIBUTE_NAMES.wallConnections]));
  }
  return [];
}

export const getArrNullNamesWallConnections = (wallIndex: number): FeaturesName_NodesT[] => {
  const wallConnections = getWallConnections(wallIndex);

  if (wallConnections.length < 1) return [];

  let arrNullNamesWallConnections: FeaturesName_NodesT[] = []
  wallConnections.forEach((featureName) => {
    const featureNameNull = featureName.split(".").join("_") as FeaturesName_NodesT;
    arrNullNamesWallConnections.push(featureNameNull)
  })

  return arrNullNamesWallConnections;

}

export const filteringBaseFeatures = (
  arrNullNamesWallConnections: FeaturesName_NodesT[]
): FeaturesName_NodesT[] => {
  return arrNullNamesWallConnections.filter((nullNameFeature) => {
    const isFeatureDoor = checkFeatureDoor(nullNameFeature);
    const isFeatureOpening = checkFeatureOpening(nullNameFeature);
    const isWindowPositionForCabinetsBase =
      checkWindowPositionForCabinetsBase(nullNameFeature);
    return isFeatureDoor || isFeatureOpening || isWindowPositionForCabinetsBase;
  });
};

export const isFeaturesModelNullName = (
  modelNullName: CabinetsAndFeatures_NodesT
): modelNullName is FeaturesName_NodesT => {
  return (
    modelNullName.includes(NODES_THREEKIT.MODEL_WINDOW) ||
    modelNullName.includes(NODES_THREEKIT.MODEL_DOOR) ||
    modelNullName.includes(NODES_THREEKIT.MODEL_OPENING)
  );
};