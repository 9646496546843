import React, { useEffect, useRef, useState } from "react";
import s from "./FinishingItems.module.scss";
import Cabinet01 from "./../../../../assets/images/Cabinets/Cabinet_01.png";
import NotImage from "./../../../../assets/images/Cabinets/not_image.png";
import { DistributorLinks, DistributorLinkT } from "./../DistributorLinks/DistributorLinks";
import { InputNumberCount } from "../../../StandartComponents/InputNumberCount/InputNumberCount";
import { useAppSelector } from "../../../../hooks/useStoreHooks";
import { getSummaryFinishingInfo } from "../../../../store/selectors/summarySelectors";

export const FinishingItems = ({ ...props }) => {
  const arrSummaryFinishingInfo = useAppSelector(getSummaryFinishingInfo);
  return (
    <div className={s.productsListWrap}>
      <h4 className={s.titleProductsList}>Finishing Items</h4>
      <div className={s.productsList}>
        {!!arrSummaryFinishingInfo.length &&
          arrSummaryFinishingInfo.map((finishingInfo) => {
            const distributorsLinks: DistributorLinkT[] = [
              {
                text: "Lowes",
                link: finishingInfo["Lowes Part Link"],
              },
            ];

            return (
              // <div className={s.productsItem} key={`${finishingInfo["ItemNumber"]}${finishingInfo["Framed"]}${finishingInfo["Color"]}`}>
              <div className={s.productsItem} key={`${finishingInfo["ItemNumber"]}${finishingInfo["Color"]}`}>
                <div className={s.imgWrap}>
                  <img
                    src={
                      finishingInfo["thumbnail"] &&
                      finishingInfo["thumbnail"] !== "https://thumbnail_xyz"
                        ? `${finishingInfo["thumbnail"]}`
                        : NotImage
                    }
                    alt={finishingInfo["Description"]}
                  />
                </div>
                <div className={s.textContent}>
                  <div className={s.descrWrap}>
                    <p className={s.productTitle}>
                      {finishingInfo["Description"]}
                    </p>
                    <div className={s.productInfoRow}>
                      <p>Whalen Model #:</p>
                      <p>{finishingInfo["ModelNumber"]}</p>
                    </div>
                    <div className={s.productInfoRow}>
                      <p>Color:</p>
                      <p>{finishingInfo["Color"]}</p>
                    </div>
                    <div className={s.productInfoRow}>
                      <p>Lowes Item #:</p>
                      <p>{finishingInfo["Lowes Part Link"] ? <a href={finishingInfo["Lowes Part Link"]} target="_blank">{finishingInfo["LowesItem"]}</a> : finishingInfo["LowesItem"]}</p>
                    </div>
                    {/* <DistributorLinks values={distributorsLinks} /> */}
                    {/* <p className={s.productDescr}>
                      TEST 1Drawer, 1 Door Base Cabinet, 24" x 40" x 4", LUM
                      Lighting, , Dimmable, OM Audio, Electrical Outlet, USB
                      Charging Ports, Left Hinge
                    </p> */}
                  </div>
                  {/* <div className={s.countAndPrice}>
                    <div className={s.countWrap}>
                      <InputNumberCount valueDefault={finishingInfo["count"]} />
                    </div>
                    <div className={s.priceWrap}>
                      <span>MSRP: </span>
                      {finishingInfo["MSRP"] !== "TBD" ? `$${Number(finishingInfo["MSRP"]) * finishingInfo["count"]}` : "TBD"}
                    </div>
                  </div> */}
                  <div className={s.countAndPrice}>
                    <div className={s.countWrap}>X {finishingInfo["count"]}</div>
                    <div className={s.priceWrap}>
                      <span>MSRP: </span>
                      {finishingInfo["MSRP"] !== "TBD" ? `$${(Number(finishingInfo["MSRP"]) * finishingInfo["count"]).toFixed(2)}` : "TBD"}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}

      </div>
    </div>
  );
};
