import { SelectedModelNameT } from "../../store/slices/settingsUI/typesSettingsUISlice";
import { FeaturesName_NodesT, NODES_THREEKIT } from "../../utils/constants/nodesNamesThreekit";
import { setActiveColliderThreekit } from "../../utils/threekit/general/setFunctions";
import { checkSinkBaseCabinet } from "../cabinets/checkModels";
import { getСompletedModelsNullNames } from "./../cabinets/getNodesCabinets";
import { checkWindowPositionForCabinetsBaseSink } from "./positionsFeature";

export const checkWindowsPhysicsForCabinetsBaseSink = (nullNameCabinetBase: SelectedModelNameT) => {
  if (!checkSinkBaseCabinet(nullNameCabinetBase)) return;
  const completedWindowsNullNames = getСompletedModelsNullNames(NODES_THREEKIT.MODEL_WINDOW);
  completedWindowsNullNames.forEach((nullName) => {
    const isWindowPositionForCabinetsBaseSink = checkWindowPositionForCabinetsBaseSink(nullName as FeaturesName_NodesT);
    if (isWindowPositionForCabinetsBaseSink) {
      setActiveColliderThreekit({ name: nullName, value: false });
    }
  });
};

export const enablePhysicsForAllWindows = () => {
  const completedWindowsNullNames = getСompletedModelsNullNames(NODES_THREEKIT.MODEL_WINDOW);
  completedWindowsNullNames.forEach((nullName) => {
    setActiveColliderThreekit({ name: nullName, value: true });
  });
};
