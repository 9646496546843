import { useAppSelector } from "../../../../../hooks/useStoreHooks";
import {
  getSummaryCabinetsInfo,
  getSummaryFinishingInfo,
  getTotalPrice,
} from "../../../../../store/selectors/summarySelectors";
import NotImage from "./../../../../../assets/images/Cabinets/not_image.png";
import s from "./Products.module.scss";

export const Products = ({ ...props }) => {
  const { snapshotsObj } = props;
  const arrSummaryCabinetsInfo = useAppSelector(getSummaryCabinetsInfo);
  const arrSummaryFinishingInfo = useAppSelector(getSummaryFinishingInfo);
  const totalPrice = useAppSelector(getTotalPrice);
  const totalCountCabinets = arrSummaryCabinetsInfo.reduce((total, cabinetObj) => (total += cabinetObj.count), 0);
  const totalCountFinishing = arrSummaryFinishingInfo.reduce((total, cabinetObj) => (total += cabinetObj.count), 0);
  return (
    <div className={s.productsList}>
      <div className={s.blockTitle}>What you need</div>
      <table>
        <thead>
          <tr>
            <th>Line # </th>
            <th>Image</th>
            <th>Qty</th>
            <th>Whalen Model #</th>
            <th>Color</th>
            <th>Description</th>
            <th>Lowe’s MSRP</th>
            <th>Lowes Item #</th>
          </tr>
        </thead>
        <tbody>
          {!!arrSummaryCabinetsInfo.length &&
            arrSummaryCabinetsInfo.map((cabinetsInfo, indx) => {
              return (
                <tr key={cabinetsInfo["ModelNumber"]}>
                  <td>{indx + 1}</td>
                  <td>
                    <img
                      className={s.img}
                      src={
                        cabinetsInfo["thumbnail"] && cabinetsInfo["thumbnail"] !== "https://thumbnail_xyz"
                          ? `${cabinetsInfo["thumbnail"]}`
                          : NotImage
                      }
                      alt={cabinetsInfo["Description"]}
                    />
                  </td>
                  <td>({cabinetsInfo["count"]})</td>
                  <td>{cabinetsInfo["ModelNumber"]}</td>
                  <td>{cabinetsInfo["Color"]}</td>
                  <td>{cabinetsInfo["Description"]}</td>
                  <td>
                    {cabinetsInfo["MSRP"] !== "TBD"
                      ? `$${(Number(cabinetsInfo["MSRP"]) * cabinetsInfo["count"]).toFixed(2)}`
                      : "TBD"}
                  </td>
                  <td
                  // style={{
                  //   borderLeft: "solid 1px #000",
                  //   borderRight: "solid 1px #000",
                  //   color: "blue",
                  // }}
                  >
                    {/* <a href={cabinetsInfo["Lowes Part Link"]}>Coming Soon</a> */}
                    {cabinetsInfo["Lowes Part Link"] ? (
                      <a href={cabinetsInfo["Lowes Part Link"]} target="_blank">
                        {cabinetsInfo["LowesItem"]}
                      </a>
                    ) : (
                      cabinetsInfo["LowesItem"]
                    )}
                  </td>
                </tr>
              );
            })}

          {!!arrSummaryFinishingInfo.length &&
            arrSummaryFinishingInfo.map((cabinetsInfo, indx) => {
              return (
                <tr key={cabinetsInfo["ModelNumber"]}>
                  <td>{arrSummaryCabinetsInfo.length + indx + 1}</td>
                  <td>
                    <img
                      className={s.img}
                      src={
                        cabinetsInfo["thumbnail"] && cabinetsInfo["thumbnail"] !== "https://thumbnail_xyz"
                          ? `${cabinetsInfo["thumbnail"]}`
                          : NotImage
                      }
                      alt={cabinetsInfo["Description"]}
                    />
                  </td>
                  <td>({cabinetsInfo["count"]})</td>
                  <td>{cabinetsInfo["ModelNumber"]}</td>
                  <td>{cabinetsInfo["Color"]}</td>
                  <td>{cabinetsInfo["Description"]}</td>
                  <td>
                    {cabinetsInfo["MSRP"] !== "TBD"
                      ? `$${(Number(cabinetsInfo["MSRP"]) * cabinetsInfo["count"]).toFixed(2)}`
                      : "TBD"}
                  </td>
                  <td
                  // style={{
                  //   borderLeft: "solid 1px #000",
                  //   borderRight: "solid 1px #000",
                  //   color: "blue",
                  // }}
                  >
                    {/* <a href={cabinetsInfo["Lowes Part Link"]}>Coming Soon</a> */}
                    {cabinetsInfo["Lowes Part Link"] ? (
                      <a href={cabinetsInfo["Lowes Part Link"]} target="_blank">
                        {cabinetsInfo["LowesItem"]}
                      </a>
                    ) : (
                      cabinetsInfo["LowesItem"]
                    )}
                  </td>
                </tr>
              );
            })}

          {!!!arrSummaryCabinetsInfo.length && !!!arrSummaryFinishingInfo.length && (
            <tr>
              <td colSpan={10}>Cabinets are missing. Start building the kitchen.</td>
            </tr>
          )}
        </tbody>
        <tfoot>
          <tr>
            <td>Totals</td>
            <td></td>
            <td>{totalCountCabinets + totalCountFinishing}</td>
            <td></td>
            <td></td>
            <td></td>
            <td>{!isNaN(totalPrice) ? `$ ${totalPrice}` : "TBD"}</td>
            <td></td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};
