import { IDisplayAttributeArrayValue, ISceneResult } from "@threekit-tools/treble/dist/types";
import { CabinetsAndFeatures_NodesT, NAME_BASE_CABINET_CORNER_STUB, NAME_BASE_CABINET_CORNER_STUB_CLICK_IT } from "../../../utils/constants/nodesNamesThreekit";
import { getItemNodeFromNullModel } from "../../../utils/threekit/general/getFunctions";

/**
* Функція для перевірки моделі на кутову модель Lazy Suzan.
@param {ISceneResult} modelItem - Item моделі з Threekit.
@returns {Boolean} true/false - є модель кутовою Lazy Suzan чи ні.
*/
export const checkIfLazySuzanCornerCabinet = (modelItem: ISceneResult | IDisplayAttributeArrayValue): boolean => {
  if (!modelItem || !modelItem["name"]) return false;
  const nameItem = modelItem["name"]
  return nameItem.includes("Corner") && nameItem.includes("Lazy") && nameItem.includes("Susan");
};

/**
* Функція для перевірки моделі на кутову модель-заглушку для напольних шкафів.
* 
@param {ISceneResult} modelItem - Item моделі з Threekit.
@returns {Boolean} true/false - є модель кутовою чи ні.
*/
export const checkIfCornerEmptyCabinetBase = (modelItem: ISceneResult): boolean => {
  if (!modelItem || !modelItem["name"]) return false;
  return modelItem["name"] === NAME_BASE_CABINET_CORNER_STUB_CLICK_IT;
  // return modelItem["name"].includes("Corner") && modelItem["name"].includes("empty") && modelItem["name"].includes("Cabinets Base");
};

/**
 * Функція для перевірки моделі на кутову модель Blind.
 *
 * @param {ISceneResult} modelItem - Item моделі з Threekit.
 * @returns {Boolean} true/false - є модель кутовою Blind чи ні
 */
export const checkIfBlindCornerCabinet = (modelItem: ISceneResult): boolean => {
  if (!modelItem || !modelItem["name"]) return false;
  return modelItem["name"].includes("Corner") && modelItem["name"].includes("Blind");
};

/**
 * Функція для перевірки моделі на кутову модель Blind.
 *
 * @param {ISceneResult} nullNameModel - Item моделі з Threekit.
 * @returns {Boolean} true/false - є модель кутовою Blind чи ні
 */
export const checkIfBlindCornerCabinetFromNullName = (nullNameModel: string): boolean => {
  const modelItem = getItemNodeFromNullModel({ name: nullNameModel });
  return checkIfBlindCornerCabinet(modelItem);
};

/**
 * Функція для перевірки кутової моделі Blind на ліву чи праву.
 *
 * @param {ISceneResult} nameItem - Ім'я Item моделі з Threekit.
 * @returns {Boolean} true - модель ліва, false - модель права
 */
export const checkIfBlindCornerCabinetLeft = (nameItem: string): boolean => {
  if (nameItem.includes("Blind")&& nameItem.includes("Left")) {
    return true;
  }
  return false;
};

/**
  * Функція для перевірки моделі на кутову модель.
  * 
  @param {ISceneResult} modelItem - Item моделі з Threekit.
  @returns {Boolean} true/false - є модель кутовою чи ні.
  */
export const checkIfCornerCabinet = (modelItem: ISceneResult): boolean => {
  if (!modelItem || !modelItem["name"]) return false;
  return modelItem["name"].includes("Corner");
};
export const checkIfCornerCabinetFromNullName = (nullNameModel: CabinetsAndFeatures_NodesT): boolean => {
  const modelItem = getItemNodeFromNullModel({ name: nullNameModel });
  if (!modelItem || !modelItem["name"]) return false;
  return modelItem["name"].includes("Corner");
};
