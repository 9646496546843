import { IntervalsWallsT } from "./wallsGeneral";

const arePointsEqual = (point1: THREE.Vector2, point2: THREE.Vector2) =>
  point1.x === point2.x && point1.y === point2.y;

export const isClosedContour = (segments: IntervalsWallsT): boolean => {
  const graph = new Map<string, Set<string>>();

  // Створюємо граф для кожної точки
  segments.forEach(segment => {
    const startKey = getPointKey(segment.start);
    const endKey = getPointKey(segment.end);

    if (!graph.has(startKey)) {
      graph.set(startKey, new Set());
    }
    if (!graph.has(endKey)) {
      graph.set(endKey, new Set());
    }

    graph.get(startKey)!.add(endKey);
    graph.get(endKey)!.add(startKey);
  });

  const visitedGlobal = new Set<string>();

  function dfs(current: string, start: string, visited: Set<string>, depth: number): boolean {
    if (visited.has(current)) {
      return current === start && depth > 2; // знайдено замкнутий контур
    }

    visited.add(current);

    const neighbors = graph.get(current) || new Set();
    for (const neighbor of Array.from(neighbors)) {
      if (dfs(neighbor, start, new Set(visited), depth + 1)) {
        return true;
      }
    }

    return false;
  }

  // Перевіряємо кожну точку на можливість замкнутого контуру
  for (const startPoint of Array.from(graph.keys())) {
    if (!visitedGlobal.has(startPoint)) {
      if (dfs(startPoint, startPoint, new Set(), 0)) {
        return true;
      }
      visitedGlobal.add(startPoint);
    }
  }

  return false;
};

function getPointKey(point: { x: number, y: number }): string {
  return `${point.x}-${point.y}`;
}

// Old function v.1.0.0

// export const isClosedContour = (segments: IntervalsWallsT): boolean => {

//   const visited = new Set();
//   let hasClosedContour = false;

//   function dfs(currentSegment: any, prevSegment: any) {

//     const currentKey = `${currentSegment.start.x}-${currentSegment.start.y}-${currentSegment.end.x}-${currentSegment.end.y}`;
//     visited.add(currentKey);

//     for (const nextSegment of segments) {
//       const nextSegmentsKey = `${nextSegment.start.x}-${nextSegment.start.y}-${nextSegment.end.x}-${nextSegment.end.y}`;
//       if (nextSegment === prevSegment || currentKey === nextSegmentsKey) {
//         continue; // Пропускаємо попередній сегмент, щоб уникнути повернення
//       }

//       if (
//         arePointsEqual(currentSegment.end, nextSegment.start) ||
//         arePointsEqual(currentSegment.end, nextSegment.end) ||
//         arePointsEqual(currentSegment.start, nextSegment.start) ||
//         arePointsEqual(currentSegment.start, nextSegment.end)
//       ) {
//         if (visited.has(nextSegmentsKey)) {
//           hasClosedContour = true;
//         } else {
//           dfs(nextSegment, currentSegment);
//         }
//       }
//     }
//   }

//   for (const segment of segments) {
//     if (!visited.has(`${segment.start.x}-${segment.start.y}-${segment.end.x}-${segment.end.y}`)) {
//       dfs(segment, null);
//       if (hasClosedContour) {
//         return true;
//       }
//     }
//   }

//   return false;
// };
