import { ICoordinates, PlayerToolEvent } from "@threekit-tools/treble/dist/types";
import {
  CabinetsAndFeatures_NodesT,
  ModelsName_NodesT,
  NODES_THREEKIT,
  WallItemT,
} from "../../../constants/nodesNamesThreekit";
import {
  getItemNodeFromNullModel,
  getNameNodeThreekit,
  getNodeFromId,
  getTranslationThreekit,
} from "../../general/getFunctions";
import {
  ArrNamesCornerWallsT,
  getInternalCornerCoordsForWalls,
  isEqualCoordsTolerance,
} from "../../../../functionsConfigurator/cabinets/addCornerModelBase";
import { once } from "../../../../pattern/once";
import {
  MoveDirectionOnWallT,
} from "../../../../functionsConfigurator/cabinets/cabinetsBase/moving/moveAllCabinetsBaseOnWall";
import {
  checkApproachToCornerFromWallSidePoints,
  getCornerDistanceSorted,
  getDirectionIntervalEmpty,
  getModelPosition,
  getMoveDistanceModels,
  getObjDistance,
  getObjWallsForCalculateDistance,
  getWallNameFromMaxDistanceInCorner,
  isEmptyIntervalInWall,
  ObjWallsForCalculateDistanceT,
} from "../toolsDragCabinetsBase/generalFunc";
import {
  AllWallsIntervalsWallT,
  getIntervalsWallCabinetsForAllWalls,
} from "../../../../functionsConfigurator/intervals/getIntervalsOnWallForCabinetsWall";
import {
  CORNER_EMPTY_WALL_SIZE_X,
  CORNER_EMPTY_WALL_SIZE_Z,
} from "../../../../functionsConfigurator/cabinets/constatns";
import { checkApproachToCornerCabinetsWall, checkIfWallCornerCabinet } from "./generalFunc";
import { addCornerModelWall, checkModelWallInCornerPoint } from "../../../../functionsConfigurator/cabinets/addCornerModelWall";
import { moveCabinetWallOnWallForEmptyCorner } from "../../../../functionsConfigurator/cabinets/cabinetsWall/moving/moveAllCabinetsWallOnWall";
import { ATTRIBUTES_MOULDING, updateMoulding } from "../../../../functionsConfigurator/cabinets/configuration/moulding";
import { getSizeModelBoxFromAssetCabinetWall } from "../../../../functionsConfigurator/cabinets/cabinetsWall/size";
import { getConfiguratorModelFromNullName } from "../../../../functionsConfigurator/cabinets/configuration/decorativePanel";
import { Dispatch } from "@reduxjs/toolkit";
import { updatePositionConnectorBottom } from "../../../../functionsConfigurator/cabinets/cabinetsWall/updatePositionConnectorBottom";
import { ATTRIBUTES_NAMES_THREEKIT } from "../../../constants/attributesThreekit";
import { checkCountertopCabinetsBase } from "../../../../functionsConfigurator/cabinets/configuration/countertop";
import { updateFillerStripForCabinetsWallOTR } from "../../../../functionsConfigurator/cabinets/configuration/fillerStripOTR";
import { checkCabinetsWallConnectors } from "../../../../functionsConfigurator/cabinets/cabinetsWall/checkCabinetsWallConnectors";
import { updateFrigePanels } from "../../../../functionsConfigurator/cabinets/configuration/fridgePanels";
import { checkScaleOrRemoveCornerEmpty } from "../../../../functionsConfigurator/cabinets/cabinetsWall/checkScaleOrRemoveCornerEmpty";
import { checkCabinetsBaseVerticalConnectors } from "../../../../functionsConfigurator/cabinets/cabinetsBase/checkCabinetsBaseVerticalConnectors";
import { updateDimensionsCabinetsWall } from "../../../../functionsConfigurator/dimensions/dimensionsCabinetsWall";
import { isOnDimensions } from "../../../../functionsConfigurator/dimensions/generalDimensions";
import { checkIfCornerCabinet } from "../../../../functionsConfigurator/cabinets/cabinetsBase/checkCornersCabinetsBase";
import { TOOLS_WHALEN } from "../toolsGeneral";
import { offAllDecorativeWallCabinets } from "../../../../functionsConfigurator/cabinets/cabinetsWall/offAllDecorativeWallCabinets";
import { isUpperPantryCabinetWall } from "../../../../functionsConfigurator/cabinets/checkModels";
import { checkPhisicsBaseCabinets } from "../../../../functionsConfigurator/cabinets/cabinetsBase/checkPhisicsBaseCabinets";
import { checkConnectorsForUpperPantry } from "../../../../functionsConfigurator/cabinets/cabinetsWall/checkConnectorsForUpperPantry";
import { getPlaneNameFromWallName } from "../../../../functionsConfigurator/wallsAndFloor/getWallPlanesInfo";

const getSelectedFirstItem = () => {
  //@ts-ignore
  const selectedIds = window.threekit.player.selectionSet.ids;
  if (selectedIds.length < 1) return undefined;
  const selectedFirstItem = selectedIds[0];
  return selectedFirstItem;
};

const checkIsThereWallModel = (ev: PlayerToolEvent) => {
  const nameCabinetWall = NODES_THREEKIT.MODEL_CABINET_WALL;

  let selectedFirstItem = null;
  if (ev.hitNodes.length < 1) return selectedFirstItem;
  if (ev.hitNodes[0]["hierarchy"].length < 1) return selectedFirstItem;

  selectedFirstItem = ev.hitNodes[0]["hierarchy"].find((item) =>
    item["name"].includes(nameCabinetWall)
  );

  return selectedFirstItem;
};

const handleRequestAnimationFrame = (
  objWallsForCalculateDistance: ObjWallsForCalculateDistanceT,
  modelId: any,
  intervalsWallCabinetsForAllWalls: AllWallsIntervalsWallT,
  changeCabinets: any
) => {
  const modelItem = getItemNodeFromNullModel({ id: modelId });
  const modelPos = getModelPosition(modelId);
  const modelNode = getNodeFromId(modelId);
  const modelNullName = modelNode["name"] as ModelsName_NodesT;
  const modelSize = getSizeModelBoxFromAssetCabinetWall(modelNullName);

  const objDistance = getObjDistance(objWallsForCalculateDistance, modelPos);

  // check base corner cabinet from id "1022", "1023"
  if (checkIfWallCornerCabinet(modelNullName)) {

    const cornerDistance = checkApproachToCornerFromWallSidePoints(objDistance, modelSize, modelPos);

    if (Object.keys(cornerDistance).length === 2) {
      const cornerWallsSortedLeftRight =
        getCornerDistanceSorted(cornerDistance);
      const maxDistanceWallName =
        getWallNameFromMaxDistanceInCorner(cornerDistance);

      const configuratorCornerModel = getConfiguratorModelFromNullName(modelNullName);

      if (maxDistanceWallName === cornerWallsSortedLeftRight[0]) {
        configuratorCornerModel.setConfiguration({
          [ATTRIBUTES_NAMES_THREEKIT.ROTATE_CABINET_CORNER]: "right",
        });
      } else if (maxDistanceWallName === cornerWallsSortedLeftRight[1]) {
        configuratorCornerModel.setConfiguration({
          [ATTRIBUTES_NAMES_THREEKIT.ROTATE_CABINET_CORNER]: "left",
        });
      }

    }
    return;
  }

  const cornerDistance = checkApproachToCornerCabinetsWall(objDistance, modelSize);

  if (Object.keys(cornerDistance).length === 2) {
    const maxDistanceWallName =
      getWallNameFromMaxDistanceInCorner(cornerDistance);
    const cornerWallsSortedLeftRight = getCornerDistanceSorted(cornerDistance);
    const planeName = getPlaneNameFromWallName(maxDistanceWallName);
    const intervalsForWallMaxDistance =
      intervalsWallCabinetsForAllWalls[planeName];
    const directionIntervalEmpty = getDirectionIntervalEmpty(
      maxDistanceWallName,
      cornerWallsSortedLeftRight
    );
    const offsetDistance =
      directionIntervalEmpty === "left"
        ? CORNER_EMPTY_WALL_SIZE_Z
        : CORNER_EMPTY_WALL_SIZE_X;

    const isEmptyInterval = isEmptyIntervalInWall(
      intervalsForWallMaxDistance,
      offsetDistance,
      directionIntervalEmpty,
      modelNullName
    );

    if (
      isEmptyInterval &&
      cornerDistance[maxDistanceWallName] &&
      (cornerDistance[maxDistanceWallName].distanceFront - modelSize["x"] / 2) > offsetDistance / 2
    ) {

      const moveDistance = getMoveDistanceModels(
        directionIntervalEmpty,
        offsetDistance,
        intervalsForWallMaxDistance
      );

      const moveDirection =
        directionIntervalEmpty === "left" ? "right" : "left";

      changeCabinets(
        maxDistanceWallName,
        cornerWallsSortedLeftRight,
        moveDirection,
        moveDistance
      );

    }

    // check Moulding Upper and Moulding Lower
    const isCornerCabinet = checkIfCornerCabinet(modelItem);
    if (cornerDistance[maxDistanceWallName]["distanceFront"] < modelSize["x"] && !isCornerCabinet) {
      const configuratorModel = getConfiguratorModelFromNullName(modelNullName);
      if (maxDistanceWallName === cornerWallsSortedLeftRight[0]) {
        configuratorModel.setConfiguration({
          [ATTRIBUTES_MOULDING.MOULDING_TOP_STYLE]: "Right",
          [ATTRIBUTES_MOULDING.MOULDING_BOTTOM_STYLE]: "Right",
        })
      }
      if (maxDistanceWallName === cornerWallsSortedLeftRight[1]) {
        configuratorModel.setConfiguration({
          [ATTRIBUTES_MOULDING.MOULDING_TOP_STYLE]: "Left",
          [ATTRIBUTES_MOULDING.MOULDING_BOTTOM_STYLE]: "Left",
        })
      }
    }

  }
};

let selectedWallModelId: string | undefined = undefined;
let startModelPosistion: ICoordinates = {x: 0, y: 0, z: 0};
export const dragCabinetsWall = (dispatch: Dispatch) => {

  return {
    active: true,
    enabled: true,
    key: TOOLS_WHALEN.DRAG_CABINETS_WALL,
    handlers: {
      mousedown: (ev: PlayerToolEvent) => {
        
        const selectedWallModel = checkIsThereWallModel(ev);
        if (selectedWallModel) {
          startModelPosistion = getTranslationThreekit({id: selectedWallModel["nodeId"]});
          
          checkCabinetsWallConnectors(selectedWallModel["nodeId"]);
          checkCabinetsBaseVerticalConnectors(selectedWallModel["nodeId"]);

          const nullNameModel = getNameNodeThreekit({id: selectedWallModel["nodeId"]}) as CabinetsAndFeatures_NodesT;
          const isUpperPantry = isUpperPantryCabinetWall(nullNameModel);
          if (isUpperPantry) {
            checkPhisicsBaseCabinets(selectedWallModel["nodeId"], false);
            checkConnectorsForUpperPantry(selectedWallModel["nodeId"], false);
            // setConfiguration(
            //   ATTRIBUTES_NAMES_THREEKIT.PHYSICS_CABINETS_BASE,
            //   false
            // );
          }
        }

        return false;
        
      },
      mouseup: (ev: PlayerToolEvent) => {

        // const typeConnectingTopMoulding = getTypeConnectingTopCabinetsWall();
        // console.log('typeConnectingTopMoulding --- ==== ',typeConnectingTopMoulding);

        // const fullInfoAllCabinetsWall = getFullInfoAllCabinetsWall();
        // console.log('fullInfoAllCabinetsWall --- ==== ',fullInfoAllCabinetsWall);

        // const size = getSizeFullForAllCabinetsWall();
        // console.log('size --- ==== ',size);

        // const intervalsWallCabinetsForAllWalls = getIntervalsWallCabinetsForAllWalls();
        // console.log('intervalsWallCabinetsForAllWalls --- ==== ',intervalsWallCabinetsForAllWalls);

        // const testIntersects = checkIntersectModelFromModelsList("Model_Cabinets_Wall_0", NODES_THREEKIT.MODEL_CABINET_WALL);
        // console.log('testIntersects --- ==== ',testIntersects);

        // const testIntersectsModels = getIntersectModelsForModel("Model_Cabinets_Wall_0", NODES_THREEKIT.MODEL_CABINET_WALL);
        // console.log('testIntersectsModels --- ==== ',testIntersectsModels);

        if (selectedWallModelId !== undefined) {
          const endModelPosistion = getTranslationThreekit({id: selectedWallModelId});
          if (!isEqualCoordsTolerance(startModelPosistion, endModelPosistion, 0.001)) {
            updatePositionConnectorBottom(selectedWallModelId);
            checkCountertopCabinetsBase();
            updateFillerStripForCabinetsWallOTR();
            checkScaleOrRemoveCornerEmpty(dispatch);
            // checkCornersEmptyRemoveCabinetsWall(dispatch);
            updateDimensionsCabinetsWall();
          }
          updateFrigePanels();
          updateMoulding();

          const nullNameModel = getNameNodeThreekit({id: selectedWallModelId}) as CabinetsAndFeatures_NodesT;
          const isUpperPantry = isUpperPantryCabinetWall(nullNameModel);
          if (isUpperPantry) {
            checkPhisicsBaseCabinets(selectedWallModelId, true);
            checkConnectorsForUpperPantry(selectedWallModelId, true);
            // setConfiguration(
            //   ATTRIBUTES_NAMES_THREEKIT.PHYSICS_CABINETS_BASE,
            //   true
            // );
          }

        }
      
        selectedWallModelId = undefined;
        return false;
      },
      drag: (ev: PlayerToolEvent) => {
        // const selectedFirstItem = getSelectedFirstItem();

        // if (selectedFirstItem && selectedFirstItem.length > 0) {
          const selectedWallModel = checkIsThereWallModel(ev);
          if (selectedWallModel) {
            selectedWallModelId = selectedWallModel["nodeId"];
            runMovedCabinetsWall(() => {});
          }
        // }

        return false;
      },
    },
  };
};

const runMovedCabinetsWall = (addModelToCorner: () => void) => {
  const objWallsForCalculateDistance = getObjWallsForCalculateDistance();
  const intervalsWallCabinetsForAllWalls =
    getIntervalsWallCabinetsForAllWalls();

  const changeCabinets = once(
    (
      wallName: WallItemT,
      cornerWallsNames: ArrNamesCornerWallsT,
      moveDirection: MoveDirectionOnWallT,
      moveDistance: number
    ) => {
      const cornerCoords = getInternalCornerCoordsForWalls(cornerWallsNames);
      const isBusyCornerPoint = checkModelWallInCornerPoint(cornerCoords);
      // перевіряємо наявність кутової моделі в куті
      // якщо в куті вже є кутова модель, то зміни не робимо
      if (isBusyCornerPoint) return;

      // cancelDragModelCustom();
      // moveAllCabinetsBaseOnWall(wallName, moveDirection, moveDistance);
      const planeName = getPlaneNameFromWallName(wallName);
      moveCabinetWallOnWallForEmptyCorner(
        wallName,
        intervalsWallCabinetsForAllWalls[planeName],
        moveDirection,
        moveDistance
      )
      // moveAllCabinetsWallOnWall(wallName, moveDirection, moveDistance);
      addCornerModelWall(cornerWallsNames);
    }
  );

  const startModelPosistion = getTranslationThreekit({id: selectedWallModelId});
  let isOffDecorativeElements = false;

  let lastCallTime = 0;
  const interval = 1000 / 10; // 10 разів в секунду (1000 мс / 10)

  const runEveryFrameWindow = (timestamp: DOMHighResTimeStamp) => {
    
    if (!selectedWallModelId) return;

    const endModelPosistion = getTranslationThreekit({id: selectedWallModelId});
    if (!isEqualCoordsTolerance(startModelPosistion, endModelPosistion, 0.001)) {
      handleRequestAnimationFrame(
        objWallsForCalculateDistance,
        selectedWallModelId,
        intervalsWallCabinetsForAllWalls,
        changeCabinets
      );
    }

    if (timestamp - lastCallTime >= interval) {
      if (isOnDimensions()) updateDimensionsCabinetsWall();
      lastCallTime = timestamp;
    }

    // Вимкнення декоративних елементів для усіх шаф групи
    if (!isOffDecorativeElements) {
      const endModelPosition = getTranslationThreekit({id: selectedWallModelId});
      const isEqualCoordsModel = isEqualCoordsTolerance(startModelPosistion, endModelPosition, 0.001);
      if (!isEqualCoordsModel) {
        offAllDecorativeWallCabinets();
        isOffDecorativeElements = true;
      }
    }

    if (requestAnimationFrame) requestAnimationFrame(runEveryFrameWindow);
  };
  runEveryFrameWindow(0);
};
