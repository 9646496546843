import { ModelCabinetWallT, NODES_THREEKIT } from "../../../utils/constants/nodesNamesThreekit";
import { getKeys } from "../../../utils/other/getObjKeysFromType";
import { getPointSpacing } from "../../../utils/three/distance";
import { getTranslationThreekit } from "../../../utils/threekit/general/getFunctions";
import { CORNER_EMPTY_WALL_SIZE_X, CORNER_EMPTY_WALL_SIZE_Z } from "../constatns";
import { getExtremePointsForCabinet } from "../getExtremeModels";
import { getСompletedModelsNullNames } from "./../getNodesCabinets";
import { getSizeModelBoxFromAssetCabinetWall } from "./size";

export type DistanceFromCabinetWallBoxT = {
  [key in ModelCabinetWallT]: number;
}
export const getDistanceFromCabinetWallBoxToCabinetsWall = (nullNameModel: ModelCabinetWallT): DistanceFromCabinetWallBoxT => {
  // const boundingBoxCurrentModel = getBoundingBoxEvalNode(nullNameModel);
  const allСompletedNullNameCabinetsWall = getСompletedModelsNullNames(NODES_THREEKIT.MODEL_CABINET_WALL);
  const objExtremePointsCornerModel = getExtremePointsForCabinet(nullNameModel);

  let objDistanceFromModelBoxToModelsNull: DistanceFromCabinetWallBoxT = {};

  allСompletedNullNameCabinetsWall.forEach((nullName) => {
    if (nullName !== nullNameModel) {
      const modelSize = getSizeModelBoxFromAssetCabinetWall(nullName);
      const position = getTranslationThreekit({name: nullName})
      // const positionVector = getVector3FromCoordinates(position)
      // const distance = boundingBoxCurrentModel.distanceToPoint(positionVector);

      // const distanceX = Math.abs(distance - modelSize["x"]/2);
      // const distanceY = Math.abs(distance - modelSize["y"]);

      const distanceModelsNulls = getPointSpacing(objExtremePointsCornerModel.rightExtremePoint, position);
      const distanceModels = distanceModelsNulls - modelSize["x"]/2 - Math.max(CORNER_EMPTY_WALL_SIZE_Z, CORNER_EMPTY_WALL_SIZE_X)

      // objDistanceFromModelBoxToModelsNull[nullName as ModelCabinetWallT] = Math.min(distanceX, distanceY);
      objDistanceFromModelBoxToModelsNull[nullName as ModelCabinetWallT] = distanceModels;

    }
  })

  return objDistanceFromModelBoxToModelsNull;
}

export const getDistanceFromCornerEmtyWallBoxToCabinetsWall = (nullNameModel: ModelCabinetWallT): ModelCabinetWallT[] => {
  const arrDistanceFromCabinetBaseBoxToCabinetsBase = getDistanceFromCabinetWallBoxToCabinetsWall(nullNameModel);
  const distanceKeys = getKeys(arrDistanceFromCabinetBaseBoxToCabinetsBase);
  let arrModelsNamesWithShortDistances: ModelCabinetWallT[] = [];
  distanceKeys.forEach((nullName) => {
    if (arrDistanceFromCabinetBaseBoxToCabinetsBase[nullName] < 0.1)
      arrModelsNamesWithShortDistances.push(nullName);
  })
  return arrModelsNamesWithShortDistances;
}