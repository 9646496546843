import { useRef, useEffect, useState, useLayoutEffect } from "react";
import { BasketCabinetSvg } from "../../../assets/images/svg/BasketCabinet";
import { CloneCabinetSvg } from "../../../assets/images/svg/CloneCabinet";
import { DoorOpeningLeft } from "../../../assets/images/svg/DoorOpeningLeft";
import { DoorOpeningRight } from "../../../assets/images/svg/DoorOpeningRight";
import { DoorDirectionValuesT, getActiveDoorDirectionForModel, updateDoorDirectionForModel } from "../../../functionsConfigurator/cabinets/configuration/doorDirection";
import { useAnimationFrameFromCameraPosition } from "../../../hooks/useAnimationFrameFromCameraPosition";
import { useAppDispatch, useAppSelector } from "../../../hooks/useStoreHooks";
import { addModels, removeModels } from "../../../store/actions/player.action";
import { getPlayerObjectOptions } from "../../../store/selectors/settingsUISelectors";
import { CabinetsAndFeatures_NodesT, ModelAppliancesT, ModelCabinetIslandT, ModelsName_NodesT } from "../../../utils/constants/nodesNamesThreekit";
import {
  getBoundingBoxEvalNode,
  getWorldPositionToScreen,
} from "../../../utils/threekit/general/getFunctions";
import { ButtonIcon } from "../../StandartComponents/Buttons/BtnIcon";
// scss
import s from "./CabinetControls.module.scss";
import { getActiveSinkSizeForModel, updateConfigurationSinkNew } from "../../../functionsConfigurator/cabinets/configuration/appliancesSink";
import { SwitchSize } from "./SwitchSize/SwitchSize";
import { SinkSizeT } from "../../../functionsUI/appliances";
import { ClockwiseRotateSvg } from "../../../assets/images/svg/ClockwiseRotate";
import { rotateCabinetsIsland } from "../../../functionsConfigurator/cabinets/cabinetsIsland/rotateCabinetsIsland";
import { getProductPositionFromNullName } from "../../../functionsUI/cabinets/getProductPositionFromNullName";
import { getSectionIdFromNullName } from "../../../functionsUI/cabinets/getSectionIdFromNullName";
import { getItemNumberFromMetadataNullName } from "../../../functionsConfigurator/cabinets/metadata";
import { SwitchFooterSmall } from "./SwitchFooterSmall/SwitchFooterSmall";
import { changeLockTranslationY, checkLockTranslationY } from "../../../functionsConfigurator/cabinets/cabinetsWall/checkFreeMovementCabinetsWall";
import { isUpperPantryCabinetWall } from "../../../functionsConfigurator/cabinets/checkModels";
import { getClonedConfiguration } from "../../../functionsConfigurator/cabinets/getClonedConfiguration";
import { setNodeMetadataValue } from "../../../utils/threekit/general/setFunctions";

// svg icons

export const CabinetControls = () => {
  const movedDiv = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();

  const cabinetControlsInfo = useAppSelector(getPlayerObjectOptions);
  

  const handleClickCloneCabinet = () => {
    
    const idModel = getItemNumberFromMetadataNullName(cabinetControlsInfo["selectedModelName"]);
    const productPosition = cabinetControlsInfo["selectedModelName"] !== "" ? getProductPositionFromNullName(cabinetControlsInfo["selectedModelName"]) : "";
    const parentSection = cabinetControlsInfo["selectedModelName"] !== "" ? getSectionIdFromNullName(cabinetControlsInfo["selectedModelName"]) : "";
    const cloneConfiguration = getClonedConfiguration(cabinetControlsInfo["selectedModelName"] as CabinetsAndFeatures_NodesT);

    const customPayload = [
      {
        optionId: productPosition,
        value: [idModel],
        parentSectionId: parentSection,
        cloneConfiguration: cloneConfiguration,
      },
    ];

    dispatch(addModels(customPayload));
  };

  const handleClickRemoveCabinet = () => {
    dispatch(removeModels([cabinetControlsInfo["selectedModelName"]]));
  };

  const nextAnimationFrameHandler = (modelNullName: ModelsName_NodesT) => {
    // const modelPosition = getTranslationThreekit({ name: modelNullName });
    // const positionToScreen = getWorldPositionToScreen(modelPosition);

    const boundingBoxModel = getBoundingBoxEvalNode(modelNullName);
    // @ts-ignore
    const centerModel = boundingBoxModel.getCenter();
    const positionToScreen = getWorldPositionToScreen(centerModel);

    if (!!movedDiv["current"]) {
      movedDiv["current"]["style"]["left"] = `${positionToScreen["x"]}px`;
      movedDiv["current"]["style"]["top"] = `${positionToScreen["y"]}px`;
    }
  };

  useAnimationFrameFromCameraPosition(
    nextAnimationFrameHandler,
    cabinetControlsInfo["visible"],
    cabinetControlsInfo["selectedModelName"]
  );

  useEffect(() => {
    if (cabinetControlsInfo["selectedModelName"])
      nextAnimationFrameHandler(cabinetControlsInfo["selectedModelName"]);
  }, [cabinetControlsInfo]);

  // Door Direction START
  const activeDoorDirection = getActiveDoorDirectionForModel(
    cabinetControlsInfo["selectedModelName"]
  );
  const [doorDirection, setDoorDirection] = useState<
    DoorDirectionValuesT | undefined
  >(activeDoorDirection);
  useLayoutEffect(() => {
    setDoorDirection(activeDoorDirection);
  }, [activeDoorDirection]);
  const handleClickDoorDirection = (
    valueDoorDirection: DoorDirectionValuesT
  ) => {
    if (doorDirection === valueDoorDirection) return;
    updateDoorDirectionForModel(
      cabinetControlsInfo["selectedModelName"],
      valueDoorDirection
    );
    setDoorDirection(valueDoorDirection);
  };
  // Door Direction END

  // Sink Size START
  const activeSinkSize = getActiveSinkSizeForModel(
    cabinetControlsInfo["selectedModelName"]
  )
  const changeSinkSize = (value: SinkSizeT) => {
    updateConfigurationSinkNew(cabinetControlsInfo["selectedModelName"], value)
  }
  // Sink Size END

  // Appliances Cabinets START
  const isAppliances = cabinetControlsInfo["selectedModelName"].includes("Appliances");
  // Appliances Cabinets END

  // Island Cabinets START
  const isCabinetIsland = cabinetControlsInfo["selectedModelName"].includes("Island");
  const handleClickRotateClockwise = () => {
    if (isCabinetIsland || isAppliances) {
      const modelNullNameIsland = cabinetControlsInfo["selectedModelName"] as ModelCabinetIslandT | ModelAppliancesT;
      rotateCabinetsIsland(modelNullNameIsland);
    }
  }
  // Island Cabinets END

  // Cabinets Wall START
  const isCabinetWall = cabinetControlsInfo["selectedModelName"].includes("_Wall_") && !isUpperPantryCabinetWall(cabinetControlsInfo["selectedModelName"] as ModelsName_NodesT);
  // const isUpperPantryCabinet = isUpperPantryCabinetWall(cabinetControlsInfo["selectedModelName"])
  const isLockTranslationY = checkLockTranslationY(cabinetControlsInfo["selectedModelName"]);
  const [isFreeMovement, setFreeMovement] = useState<boolean>(!isLockTranslationY);
  useLayoutEffect(() => {
    setFreeMovement(!isLockTranslationY);
  }, [isLockTranslationY]);
  const handleClickFreeMovement = (value: boolean) => {
    if (isCabinetWall) {
      setNodeMetadataValue({name: cabinetControlsInfo["selectedModelName"], key: "isVerticalMovement", value: value ? "1" : "0"})
      changeLockTranslationY(cabinetControlsInfo["selectedModelName"], !value);
      setFreeMovement(value);
    }
  }
  // Cabinets Wall END
  
  if (!cabinetControlsInfo["visible"]) return <></>;

  return (
    <div
      className={s.sceneCabinetControls}
      ref={movedDiv}
      onMouseUp={(e) => e.stopPropagation()}
    >
      <div className={s.head}>
        <div className={s.generalButtons}>
          {!!doorDirection && (
            <>
              <div className={s.doorControls}>
                <ButtonIcon
                  onClick={() => handleClickDoorDirection("Right")}
                  active={doorDirection === "Right"}
                >
                  <DoorOpeningLeft />
                </ButtonIcon>
                <ButtonIcon
                  onClick={() => handleClickDoorDirection("Left")}
                  active={doorDirection === "Left"}
                >
                  <DoorOpeningRight />
                </ButtonIcon>
              </div>
              <div className={s.dividerCabinetControls}></div>
            </>
          )}

          <ButtonIcon onClick={handleClickCloneCabinet}>
            <CloneCabinetSvg />
          </ButtonIcon>
          <ButtonIcon onClick={handleClickRemoveCabinet}>
            <BasketCabinetSvg />
          </ButtonIcon>
        </div>
        
        {(isCabinetIsland || isAppliances) && <div className={s.rotateIslands}>
          <ButtonIcon onClick={handleClickRotateClockwise}>
            <ClockwiseRotateSvg />
          </ButtonIcon>
        </div>}
        
      </div>

      {(!!activeSinkSize || isCabinetWall) && (
        <div className={s.footer}>
          {!!activeSinkSize && (
            <SwitchSize
              label="Sink"
              onChange={(value) => changeSinkSize(value as SinkSizeT)}
              value={activeSinkSize}
              values={[
                {
                  label: '25"',
                  value: "sink 25",
                },
                {
                  label: '33"',
                  value: "sink 33",
                },
              ]}
            />
          )}
          {isCabinetWall && (
            <SwitchFooterSmall
              label="Vertical Movement"
              onChange={(value) => handleClickFreeMovement(value)}
              defaultValue={isFreeMovement}
            />
          )}
        </div>
      )}
    </div>
  );
};
