import { SelectedModelNameT } from "../../store/slices/settingsUI/typesSettingsUISlice";
import { ID_BLIND_CORNER, ID_CORNER } from "../../utils/constants/cabinets";
import { CabinetsAndFeatures_NodesT, NODES_THREEKIT } from "../../utils/constants/nodesNamesThreekit";
import { getItemNodeFromNullModel } from "../../utils/threekit/general/getFunctions";

// ***** is Fridge or OTR Cabinet Wall *****
export const isItemNameOTR = (modelName: string): boolean => {
  return modelName.includes("OTR") || modelName.includes("Bridge")
}

export const isOTRCabinetWall = (nullName: CabinetsAndFeatures_NodesT) => {
  const itemNodeFromNullModel = getItemNodeFromNullModel({name: nullName});
  return isItemNameOTR(itemNodeFromNullModel["name"]);
}


// ***** is Upper Pantry Cabinet Wall *****
export const isItemNamePantry = (modelName: string): boolean => {
  return modelName.includes("Upper") && modelName.includes("Pantry")
}

export const isUpperPantryCabinetWall = (nullName: CabinetsAndFeatures_NodesT) => {
  const itemNodeFromNullModel = getItemNodeFromNullModel({name: nullName});
  try {
    return isItemNamePantry(itemNodeFromNullModel["name"]);
  } catch (error) {
    console.error(`Threekit Not Found node from name ${nullName}`);
    console.error(error);
    return false;
  }
}

// ***** is Appliances *****
export const isNullNameAppliances = (nullName: string): boolean => {
  return nullName.includes(NODES_THREEKIT.MODEL_APPLIANCES);
}

export const isItemNameRefrigerator = (itemName: string): boolean => {
  return itemName.includes("Refrigerator");
}

export const isNullNameAppliancesFridge = (nullName: string): boolean => {
  const itemNodeFromNullModel = getItemNodeFromNullModel({name: nullName});
  return isItemNameRefrigerator(itemNodeFromNullModel["name"]);
}

// ***** is Cabinet Base *****
export const isNullNameCabinetBase = (nullName: string): boolean => {
  return nullName.includes(NODES_THREEKIT.MODEL_CABINET_BASE);
}

export const isCornerMirrorCabinet = (id: string): boolean => {
  return id === `${ID_CORNER}_2` || id === `${ID_BLIND_CORNER}_2`;
}

// ***** is Sink Cabinet Base *****
/**
 * Функція перевіряє чи є обрана модель тумбою з раковиною.
 *
 * @param {SelectedModelNameT} modelNullName Null Name моделеі.
 * @returns {boolean} true - якщо шкаф є тумбою з раковиною розміром 36".
 */
export const checkSinkBaseCabinet = (
  modelNullName: SelectedModelNameT
): boolean => {
  if (modelNullName === undefined) return false;
  if (!isNullNameCabinetBase(modelNullName)) return false;
  const modelItem = getItemNodeFromNullModel({ name: modelNullName });
  if (modelItem === undefined) return false;
  return modelItem["name"].includes("Sink");
};